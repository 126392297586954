import { Link, useLocation } from "react-router-dom";
import {useLanguage } from '../../../lang/LangProvider';

// export default function MobileMenu({ name, link, children, openSubMenu, toggleMenu, closeSubMenu }) {
//     if (children) return <div className='single-menu' onClick={() => {openSubMenu(children)}}>{name}</div>
//     return <Link className="single-menu" onClick={event => {toggleMenu(); closeSubMenu(closeSubMenu);}} to={link}>{name}</Link>
// }

const MobileMenu = ({ name, link, children, openSubMenu, toggleMenu, closeSubMenu, idMenu }) => {
    const location = useLocation().pathname;
    const { translate } = useLanguage();

    var class_ = '';
    if (children) {
        const current = children.filter((item) => {
            return item.link == location;
        })
        if(current.length > 0){
            if(current[0].menu_id === idMenu) class_ = 'active';
        }
    }
    if (children) return <div className={`single-menu ${class_}`} onClick={() => { openSubMenu(children) }}>{translate(name)}</div>
    return <Link className={`single-menu ${location === link ? 'active' : ''}`} onClick={event => { toggleMenu(); closeSubMenu(closeSubMenu); }} to={link}>{translate(name)}</Link>
}

export default MobileMenu;