import NavClass from "./NavClass";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useRef } from "react";
import {useLanguage } from '../../../lang/LangProvider';


export default function Menu({ children, name, link }) {
    const location = useLocation();
    const menuRef = useRef(null);
    const menuClass = ['single-menu'];
    const [parent, setParent] = useState('');
    const [theClass, setTheClass] = useState('');
    const [currentLink, setCurrentLink] = useState(null);
    const { translate } = useLanguage();

    menuRef.current && menuRef.current.classList.remove('link-active');
    const currentPage = children && children.filter(current => {
        if (location.pathname === current.link) {
            menuRef.current && menuRef.current.classList.add('link-active');
        }
        return current.link === location.pathname;
    });
    if (children) menuClass.push('has-child')
    return (
        <div ref={menuRef} className={`${menuClass.join(" ")}`}>
            <div className="menu-name">
                {link ? <NavClass link={link} name={name} /> : name}
            </div>
            {children && <div className="child-menu">
                <div className="top-wave"></div>
                <div className="container">
                    <div className="child-menu-container">
                        {children.map((child, idx) => <NavClass key={idx} link={child.link} name={translate(child.name)} />)}
                    </div>
                </div>
            </div>}
        </div>
    )
}