
import useFetch from "../../customFunction/useFetch";
import PageDetail from "../../components/PageDetail";
import { useLocation } from 'react-router-dom';
import waveTop from '../../assets/wave-top.svg';
import splash from "../../assets/splash-yellow.png";
import ButtonLink from "../../components/ui/ButtonLink";
import { motion } from "framer-motion";
import {useLanguage} from '../../lang/LangProvider';

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.5 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}
const PlanEventDetail = () => {
    const location = useLocation().pathname.split("/").pop();
    const apiUrl = process.env.REACT_APP_API_URL;
    const {translate,currentLanguage} = useLanguage();

    const { data, isPending, error } = useFetch(apiUrl + 'planyourvisit?seo=' + location +'&lang='+currentLanguage);
    if (data && data){
        document.title = data.name+" | Wahoo Waterworld";
    }
    return (
        <>
            {isPending && ' '}
            {error && error.message}
            {
                data && <>
                    <motion.div
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                    >
                        <PageDetail data={data} shareButton={false} >
                            <div className="section yellow pt-0 min-125 overflow-hidden">
                                <div className="ticket-circular-slider-section">
                                    <div className="wave wave-top">
                                        {/* <div className="spacer"></div> */}
                                        <img className="img-wave" src={waveTop} alt="" />
                                        <div className="ornaments">
                                            <div className="right splash">
                                                <img src={splash} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-6 mb-6">
                                    <div className="container ">
                                        <div className="list-item mb-6 position-relative z-index">
                                            <div className="text-blue secondary-title text-bold position-relative">
                                                  {translate('plan_your_event.tarif_khusus')}
                                            </div>
                                            <div className="centered-items bigger">
                                                <ButtonLink btnColor="bg-orange" arrowColor="bg-orange" link="/group-booking">{translate('booking')}</ButtonLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PageDetail>
                    </motion.div>
                </>
            }
        </>
    );
}

export default PlanEventDetail;