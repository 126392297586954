import FirstBanner from "../../components/FirstBanner";
import DiningList from "../../components/ThingsToDo/DiningList";
import waveTop from '../../assets/wave-top.svg';
import splash from "../../assets/splash-yellow.png";

import ButtonLink from "../../components/ui/ButtonLink";
import useFetch from "../../customFunction/useFetch";
import { motion } from "framer-motion";
import {useLanguage} from '../../lang/LangProvider';

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 30,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.35, duration: 0.5 }
  },
  exit: {
    y: -30,
    opacity: 0,
    transition: { duration: 0.35 }
  }
}
const PlanYourEvent = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { translate , currentLanguage} = useLanguage();
  const { data, isPending, error } = useFetch(apiUrl + 'planyourvisit?lang='+currentLanguage);

  document.title = "Plan Your Event | Wahoo Waterworld";

  return (
    <motion.div className="position-relative"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <FirstBanner className="bg-orange">
        <div className="button-title bg-yellow">{translate('menus.plan_your_event')}</div>
      </FirstBanner>
      <div className="section pb-0 pt-0">
        <div className="container">
          {isPending && ' '}
          {error && error.message}
          {data &&
            <DiningList className="plan-page" dinings={data} imgLink={true} showButtonLink={true} />
          }
        </div>
      </div>
      <div className={`section yellow pt-0 min-125 overflow-hidden  ${isPending && 'd-none'}`}>
        <div className="ticket-circular-slider-section">
          <div className="wave wave-top">
            <div className="spacer"></div>
            <img className="img-wave" src={waveTop} alt="" />
            <div className="ornaments">
              <div className="right splash">
                <img src={splash} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="py-6 mb-6">
          <div className="container">
            <div className="list-item mb-big-5">
              <div className="text-blue secondary-title text-bold position-relative">
                {/*let you know our special rates and packages for groups of more than 50 people, Click the button on Right */}
                 {translate('plan_your_event.tarif_khusus')}
              </div>
              <div className="centered-items bigger">
                <ButtonLink btnColor="bg-orange" arrowColor="bg-orange" link="/group-booking">{translate('booking')}</ButtonLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default PlanYourEvent;
