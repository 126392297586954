import { useEffect, useState, useRef, useCallback } from "react";
import { Link } from 'react-router-dom';
import ImageCard from "./ImageCard";
import Bar from "./Bar";
import Bullet from "./Bullet";
import "./style.scss";
import { useLanguage } from '../../lang/LangProvider';

// Mandatory assets
import ageIndicator from "./age-indicator.png";
const bar_colors = {
    // 1: "#52c9e7",
    // 2: "#195aa4",
    // 3: "#fab816",
    // 4: "#f6511d",
    // 5: "#dc3735",
    1: "#52c9e7",
    2: "#52c9e7",
    3: "#52c9e7",
    4: "#52c9e7",
    5: "#52c9e7",
};
let rotate_degree = 7;
const drag_sensitivity = 5;
const drag_trigger_sensitivity = 20;
export default function RideCircularSlider({ attractions }) {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const numberSlider = attractions.length > 5 ? 3 : 0;
    const [slidePos, setSlidePos] = useState(numberSlider);
    const [attraction, setAttraction] = useState(attractions);
    const circularSliderContainer = useRef();
    const sliderRotateHolder = useRef();
    const attractionTitle = useRef();
    const clientWidth = useRef();
    const { translate } = useLanguage();
    const navigate = useCallback((direction, to = null) => {
        let newSlidePos;
        if (to !== null) {
            newSlidePos = to;
        } else {
            newSlidePos = direction === "<" ? slidePos - 1 : slidePos + 1;
        }
        setSlidePos(() => Math.max(Math.min(attractions.length - 1, newSlidePos), 0));
    }, [slidePos])
    useEffect(() => {
        function setRotateDegree() {
            if (window.innerWidth < 768) {
                rotate_degree = 5;
            } else {
                rotate_degree = 7;
            }
        }
        setRotateDegree();
        window.addEventListener('resize', setRotateDegree);
        return () => {
            window.removeEventListener('resize', setRotateDegree);
        }
    }, [])
    useEffect(() => {
        let clicked = false;
        let drag_pos = 0;
        let clickPos = 0;
        let dragged = false;
        const slider = circularSliderContainer.current;
        //set client width
        clientWidth.current = window.innerWidth;
        function resizeHandler() {
            clientWidth.current = window.innerWidth;
        }
        window.addEventListener('resize', resizeHandler);
        //set drag handler
        const mouseDownHandler = function (event) {
            if (event.target.closest('.box')) {
                clicked = true;
            }
            clickPos = event.clientX || event.touches[0].clientX;
            sliderRotateHolder.current.classList.add('dragging');
        }
        const mouseUpHandler = function (event) {
            sliderRotateHolder.current.classList.remove('dragging');
            const moved = Math.round(drag_pos / rotate_degree) * -1;
            if (moved === 0) {
                sliderRotateHolder.current.style.transform = "rotateZ(-" + slidePos * rotate_degree + "deg)";
            } else {
                // console.log(moved);
                navigate("", slidePos + moved);
            }
            if (!dragged) {
                event.target.closest('.image') && setSlidePos(parseInt(event.target.closest('.image').getAttribute('slide-key'))); //+0 supaya jadi integer
            }
            clicked = false;
            dragged = false;
        }
        const mouseMoveHandler = function (event) {
            if (clicked) {
                const clientX = event.clientX || event.touches[0].clientX;
                if (!dragged) {
                    if (Math.abs(clientX - clickPos) > drag_trigger_sensitivity) {
                        dragged = true;
                    }
                }
                drag_pos = (clientX - clickPos) / clientWidth.current * rotate_degree * drag_sensitivity;
                const drag_rotate_degree = ((slidePos * rotate_degree * -1) + drag_pos);
                sliderRotateHolder.current.style.transform = "rotateZ(" + drag_rotate_degree + "deg)";
            }
        }
        const clickEvent = ['mousedown', 'touchstart'];
        const stopEvent = ['mouseup', 'touchend', 'touchcancel'];
        const moveEvent = ['mousemove', 'touchmove'];
        clickEvent.forEach(eventName => {
            slider.addEventListener(eventName, mouseDownHandler, { capture: false });
        });
        stopEvent.forEach(eventName => {
            slider.addEventListener(eventName, mouseUpHandler, { capture: false });
        })
        moveEvent.forEach(eventName => {
            slider.addEventListener(eventName, mouseMoveHandler, { capture: false });
        })
        return () => {
            clickEvent.forEach(eventName => {
                slider.removeEventListener(eventName, mouseDownHandler);
            });
            stopEvent.forEach(eventName => {
                slider.removeEventListener(eventName, mouseUpHandler);
            })
            moveEvent.forEach(eventName => {
                slider.removeEventListener(eventName, mouseMoveHandler);
            })
        }
    }, [navigate, slidePos])
    useEffect(() => {
        const attractionTitleCur = attractionTitle.current;
        setAttraction(attractions[slidePos]);
        sliderRotateHolder.current.style.transform = "rotateZ(-" + slidePos * rotate_degree + "deg)";
        setTimeout(() => {
            attractionTitle.current.classList.add("show");
        }, 100);
        // return () => {
        //     attractionTitle.current.classList.remove('show');
        // }
    }, [slidePos,attractions]);
    return (
        <>
            {attractions && attraction ?
                <>
                    <div className="circular-slider-section">
                        <div className="container">
                            <div className="big-heading margin-bottom">
                                <div className="text-blue"><h1>{translate('menus.ride_and_attractions')}</h1></div>
                                <h5 className="secondary-title text-light-blue ">{translate('home.temukan_petualangan')}</h5>
                            </div>
                        </div>
                        <div className="relative">
                            <div className="navigation-button">
                                <button
                                    onClick={(event) => { navigate.bind(event, "<")() }}
                                    className="button-prev"
                                >
                                    <div className="arrow"></div>
                                </button>
                                <button
                                    onClick={(event) => { navigate.bind(event, ">")() }}
                                    className="button-next"
                                >
                                    <div className="arrow"></div>
                                </button>
                            </div>
                            <div className="circular-slider-container" ref={circularSliderContainer}>
                                <div className="box-container" ref={sliderRotateHolder}>
                                    {attractions.map((el, idx) => (
                                        <div className="box" key={el.id}>
                                            {/* <Link to={`ride-attraction/${el.seo_url}`}> */}
                                                <div className="image" slide-key={idx}>
                                                    <ImageCard image={baseUrl + JSON.parse(el.image_url).potrait_image} />
                                                </div>
                                            {/* </Link> */}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="ride-data-container">
                            <div className="selected-arrow">
                                <div className="arrow">
                                    <i className="fa-solid fa-chevron-up"></i>
                                </div>
                            </div>
                            <div className="big-heading margin-bottom">
                                <Link to={`ride-attraction/${attraction.seo_url}`}>
                                    <h2 className="attraction-title nowrap" ref={attractionTitle}>
                                        <span className="orange">{attraction.name}</span>
                                        <span className="detail-arrow">
                                        </span>
                                    </h2>
                                </Link>
                            </div>
                            <div className="description">
                                <div className="ride-data">
                                    <div className="age">
                                        <div className="icon">
                                            {
                                                attraction.mascot && JSON.parse(attraction.mascot).name !== null && JSON.parse(attraction.mascot).name !== '' && JSON.parse(attraction.mascot).name !== 'null' &&
                                                <img src={baseUrl+JSON.parse(attraction.mascot).url} alt={`${attraction.name} Mascot`} />
                                            }
                                        </div>
                                        {/* <div className="text">
                                            <div className="age-num">+{attraction.age} Year Old</div>
                                            <p>And adult must supervise children under {attraction.age}.</p>
                                        </div> */}
                                        <div className="text">
                                            {
                                                attraction.age > 0 ? 
                                                <div className="age-num">{translate('home.min_tinggi')} {attraction.age} cm</div> : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="stats">
                                        <div className="single-stat">
                                            <div className="name">
                                                {translate('drench')}
                                            </div>
                                            <Bar level={attraction.drench} color={bar_colors[attraction.drench]} />
                                        </div>
                                        <div className="single-stat">
                                            <div className="name">
                                                {translate('thrill')}
                                            </div>
                                            <Bar level={attraction.thrill} color={bar_colors[attraction.thrill]} />
                                        </div>
                                        <div className="single-stat">
                                            <div className="name">
                                                {translate('speed')}
                                            </div>
                                            <Bar level={attraction.speed} color={bar_colors[attraction.speed]} />
                                        </div>
                                    </div>
                                    <div className="type">
                                        {translate('rides_type')}: {attraction.category_name}
                                    </div>
                                </div>
                                <div className="description-text">
                                    {/* <h3>Description</h3> */}
                                    <p>{attraction.description}</p>
                                </div>
                            </div>
                            <Bullet attractions={attractions} current={slidePos} setSliderPos={setSlidePos} />
                        </div>
                    </div>
                </> : null
            }
        </>
    );
}
