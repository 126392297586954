import React from 'react';
import { useLanguage } from './LangProvider';

const LanguageSwitcher = () => {
    const { changeLanguage, currentLanguage } = useLanguage();

    const handleLanguageChange = (lang) => {
        changeLanguage(lang);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', position: 'absolute',top: '50%',left:'0' }}>
            <span
                onClick={() => handleLanguageChange('en')}
                style={{
                    cursor: 'pointer',
                    fontWeight: currentLanguage === 'en' ? 'bold' : 'normal',
                    color: currentLanguage === 'en' ? '#3d60a2' : '#75b4e7',
                    padding: '5px',
                }}
            >
                EN
            </span>
            <span style={{color:'#3d60a2'}}>|</span>
            <span
                onClick={() => handleLanguageChange('id')}
                style={{
                    cursor: 'pointer',
                    fontWeight: currentLanguage === 'id' ? 'bold' : 'normal',
                    color: currentLanguage === 'id' ? '#3d60a2' : '#75b4e7',
                    padding: '5px',
                }}
            >
                ID
            </span>
        </div>
    );
};

export default LanguageSwitcher;