import { Link } from 'react-router-dom';
import './style.scss';
import {useLanguage} from '../../../lang/LangProvider';

const ButtonLink = ({ children, link, arrowColor, btnColor, icon }) => {
  const { translate } = useLanguage();

    return (
        <Link to={link || ''} className='btn-link'>
            <div className={`btn-learn ${btnColor || ''} nowrap`}>{children || translate('learn_more')}</div>
            <div className={`arrow ${arrowColor || translate('learn_more')}`} ><i className={`fa-solid ${icon || 'fa-right-long'}`}></i></div>
        </Link>
    );
}

export default ButtonLink;