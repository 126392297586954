import ImageClip from "../ui/ImageClip";
import { Link } from "react-router-dom";
import mason3 from "../../assets/mason/mason3.jpg";
import {useLanguage } from '../../lang/LangProvider';

import './style.scss';
const RoomPackage = ({ rooms }) => {
    
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const { translate} = useLanguage();

    return (
        <div className="accomodation-list">
            {rooms.map((room) => (
                <a href={room.link} target="_blank" key={room.id} className="accomodation-item" style={{ backgroundImage: `url(${baseUrl+room.image_url})` }} rel="noreferrer">
                    <div className="position-relative accomodation-place p-big-1">
                        <div className="button-title book text-normal bg-yellow" ><span data-text={translate('book_now')}>{translate('book_now')}</span></div>
                        <div className="title text-bold secondary-title">{room.name}</div>
                    </div>
                </a>
            ))}

        </div>
    );
}

export default RoomPackage;